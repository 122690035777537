import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaWhatsapp, FaBars, FaTimes, FaInstagram, FaPhoneAlt } from 'react-icons/fa';
import { SiGmail } from "react-icons/si";
import logo from '../Images/VatanPsikoteknik.png';

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseMove = (dropdown) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setActiveDropdown(dropdown);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setActiveDropdown(null);
    }, 200);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleDropdownClick = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  return (
    <div>
      <div className="bg-blue-800 text-white text-sm py-2">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex flex-col md:flex-row space-x-4">
            <div className="flex items-center space-x-1 pl-4">
              <FaPhoneAlt />
              <p>0 (533) 087 73 14</p>
            </div>
            <div className="flex items-center space-x-1">
              <SiGmail />
              <p>vatangrup2011@gmail.com</p>
            </div>
          </div>
          <div className="pr-4 flex space-x-2">
            <a href="https://www.instagram.com/vatanpsikoteknik/" className="hover:text-gray-400">
              <FaInstagram size={20} />
            </a>
          </div>
        </div>
      </div>


      <motion.nav
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white shadow-md sticky top-0 z-50"
      >
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <a href="/">
              <img src={logo} alt="Logo" className="h-auto w-auto max-h-20 max-w-20 aspect-square" />
            </a>
            <div className="text-2xl font-bold text-gray-800">
              <a href="/">Vatan Psikoteknik Değerlendirme Merkezi</a>
            </div>
          </div>

          <div className="lg:hidden">
            <button onClick={toggleMobileMenu} className="text-gray-800 focus:outline-none">
              {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>

          <div className={`lg:flex items-center space-x-8 relative ${isMobileMenuOpen ? 'block' : 'hidden'} lg:block`}>
            <a href="/" className="text-gray-700 hover:text-blue-500 transition duration-300">Anasayfa</a>

            <div
              className="relative cursor-pointer"
              onMouseMove={() => handleMouseMove('corporate')}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleDropdownClick('corporate')}
            >
              <a href="?" className="text-gray-700 hover:text-blue-500 transition duration-300">Kurumsal</a>
              <AnimatePresence>
                {activeDropdown === 'corporate' && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute top-8 left-0 bg-white shadow-lg rounded-lg py-2 w-32 z-10"
                  >
                    <a href="/hakkimizda" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Hakkımızda</a>
                    <a href="/vizyonumuz" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Vizyonumuz</a>
                    <a href="/misyonumuz" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Misyonumuz</a>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div
              className="relative cursor-pointer"
              onMouseMove={() => handleMouseMove('services')}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleDropdownClick('services')}
            >
              <a href="?" className="text-gray-700 hover:text-blue-500 transition duration-300">Hizmetlerimiz</a>
              <AnimatePresence>
                {activeDropdown === 'services' && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute top-8 left-0 bg-white shadow-lg rounded-lg py-2 w-72 z-10"
                  >
                    <a href="/psikoteknik-nedir" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Psikoteknik Nedir?</a>
                    <a href="/psikoteknik-raporunu-kimler-alir" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Psikoteknik Raporunu Kimler Alır?</a>
                    <a href="/psikoteknik-belgesi-nasil-alinir" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Psikoteknik Belgesi Nasıl Alınır?</a>
                    <a href="/psikoteknik-raporunun-gecerlilik-suresi" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Psikoteknik Raporunun Geçerlilik Süresi</a>
                    <a href="/ehliyet-iade-icin-psikoteknik" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Ehliyet İade İçin Psikoteknik</a>
                    <a href="/alkollu-arac-kullanirken-ehliyete-el-konulmasi" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Alkollü Araç Kullanırken Ehliyete El Konulması</a>
                    <a href="/drift-veya-spin-ihlali-sonucunda-ehliyete-el-konulmasi" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Drift Veya Spin İhlali Sonucunda Ehliyete El Konulması</a>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div
              className="relative cursor-pointer"
              onMouseMove={() => handleMouseMove('contact')}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleDropdownClick('contact')}
            >
              <a href="?" className="text-gray-700 hover:text-blue-500 transition duration-300">İletişim</a>
              <AnimatePresence>
                {activeDropdown === 'contact' && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute top-8 right-0 bg-white shadow-lg rounded-lg py-2 w-52 z-10"
                  >
                    <a href="/iletisim-bilgilerimiz" className="block px-4 py-2 text-gray-700 hover:text-blue-500">İletişim Bilgilerimiz</a>
                    <a href="/sss" className="block px-4 py-2 text-gray-700 hover:text-blue-500">Sıkça Sorulan Sorular</a>
                    <a href="/iletisim-formu" className="block px-4 py-2 text-gray-700 hover:text-blue-500">İletişim Formu</a>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <a href="https://wa.me/905330877314" className="flex items-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300">
              <FaWhatsapp className="mr-2" /> WhatsApp Hattımız
            </a>
          </div>
        </div>

        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ duration: 0.3 }}
              className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
            >
              <motion.div className="fixed right-0 top-0 bg-white h-full w-3/4 z-50 p-4 shadow-lg">
                <button onClick={toggleMobileMenu} className="text-gray-800 focus:outline-none mb-4">
                  <FaTimes size={24} />
                </button>

                <a href="/" className="block mb-4 text-gray-700 hover:text-blue-500 transition duration-300">Anasayfa</a>

                <div className="mb-4">
                  <button
                    onClick={() => handleDropdownClick('corporate')}
                    className="w-full text-left text-gray-700 hover:text-blue-500 transition duration-300 focus:outline-none"
                  >
                    Kurumsal
                  </button>
                  <AnimatePresence>
                    {activeDropdown === 'corporate' && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="mt-2 pl-4"
                      >
                        <a href="/hakkimizda" className="block py-2 text-gray-700 hover:text-blue-500">Hakkımızda</a>
                        <a href="/vizyonumuz" className="block py-2 text-gray-700 hover:text-blue-500">Vizyonumuz</a>
                        <a href="/misyonumuz" className="block py-2 text-gray-700 hover:text-blue-500">Misyonumuz</a>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <div className="mb-4">
                  <button
                    onClick={() => handleDropdownClick('services')}
                    className="w-full text-left text-gray-700 hover:text-blue-500 transition duration-300 focus:outline-none"
                  >
                    Hizmetlerimiz
                  </button>
                  <AnimatePresence>
                    {activeDropdown === 'services' && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="mt-2 pl-4"
                      >
                        <a href="/psikoteknik-nedir" className="block py-2 text-gray-700 hover:text-blue-500">Psikoteknik Nedir?</a>
                        <a href="/psikoteknik-raporunu-kimler-alir" className="block py-2 text-gray-700 hover:text-blue-500">Psikoteknik Raporunu Kimler Alır?</a>
                        <a href="/psikoteknik-belgesi-nasil-alinir" className="block py-2 text-gray-700 hover:text-blue-500">Psikoteknik Belgesi Nasıl Alınır?</a>
                        <a href="/psikoteknik-raporunun-gecerlilik-suresi" className="block py-2 text-gray-700 hover:text-blue-500">Psikoteknik Raporunun Geçerlilik Süresi</a>
                        <a href="/ehliyet-iade-icin-psikoteknik" className="block py-2 text-gray-700 hover:text-blue-500">Ehliyet İade İçin Psikoteknik</a>
                        <a href="/alkollu-arac-kullanirken-ehliyete-el-konulmasi" className="block py-2 text-gray-700 hover:text-blue-500">Alkollü Araç Kullanırken Ehliyete El Konulması</a>
                        <a href="/drift-veya-spin-ihlali-sonucunda-ehliyete-el-konulmasi" className="block py-2 text-gray-700 hover:text-blue-500">Drift Veya Spin İhlali Sonucunda Ehliyete El Konulması</a>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <div className="mb-4">
                  <button
                    onClick={() => handleDropdownClick('contact')}
                    className="w-full text-left text-gray-700 hover:text-blue-500 transition duration-300 focus:outline-none"
                  >
                    İletişim
                  </button>
                  <AnimatePresence>
                    {activeDropdown === 'contact' && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="mt-2 pl-4"
                      >
                        <a href="/iletisim-bilgilerimiz" className="block py-2 text-gray-700 hover:text-blue-500">İletişim Bilgilerimiz</a>
                        <a href="/sss" className="block py-2 text-gray-700 hover:text-blue-500">Sıkça Sorulan Sorular</a>
                        <a href="/iletisim-formu" className="block py-2 text-gray-700 hover:text-blue-500">İletişim Formu</a>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>

                <a href="https://wa.me/905330877314" className="block px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300">
                  <FaWhatsapp className="inline-block mr-2" /> WhatsApp Hattımız
                </a>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.nav>
    </div>
  );
};

export default Navbar;