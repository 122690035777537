import React from 'react';
import { motion } from 'framer-motion';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <motion.footer
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            className="bg-gray-800 text-white py-10"
        >
            <div className="container mx-auto text-center">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                    className="mb-6"
                >
                    <p className="text-lg">Psikoteknik Testlerinde Profesyonel Hizmet</p>
                    <p className="text-sm mt-2">Sürücüler için güvenli bir yolculuk sağlıyoruz</p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.7 }}
                    className="flex flex-wrap justify-center space-x-8 mb-6"
                >
                    <a href="/" className="text-gray-400 hover:text-blue-500 transition duration-300">Anasayfa</a>
                    <a href="/hakkimizda" className="text-gray-400 hover:text-blue-500 transition duration-300">Hakkımızda</a>
                    <a href="/vizyonumuz" className="text-gray-400 hover:text-blue-500 transition duration-300">Vizyonumuz</a>
                    <a href="/misyonumuz" className="text-gray-400 hover:text-blue-500 transition duration-300">Misyonumuz</a>
                    <a href="/iletisim" className="text-gray-400 hover:text-blue-500 transition duration-300">İletişim</a>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.9 }}
                    className="flex justify-center space-x-6 mb-6"
                >
                    <a href="https://www.instagram.com/vatanpsikoteknik/" className="text-gray-400 hover:text-blue-500 transition duration-300"><FaInstagram /></a>
                    <a href="https://wa.me/905050662193" className="text-gray-400 hover:text-green-500 transition duration-300"><FaWhatsapp /></a>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1.1 }}
                >
                    <p className="text-sm">&copy; 2016-2024 Vatan Psikoteknik Değerlendirme Merkezi. Tüm Hakları Saklıdır.</p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1.3 }}
                    className="mt-4"
                >
                    <p className="text-xs text-gray-400">
                        Websiteyi Tasarlayan ve Geliştiren:{" "}
                        <a
                            href="https://github.com/tyial"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                        >
                            Tyial
                        </a>
                    </p>
                </motion.div>
            </div>
        </motion.footer>
    );
};

export default Footer;