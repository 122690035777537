import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home, About, OurVision, OurMission, WhatIsPsychotechnics, WhoGetsThePsychotechnicalReport, HowToGetPsychotechnicalCertificate, ValidityPeriodOfPsychotechnicalReport, PsychotechnicForDrivingLicenseReturn, ConfiscationOfDrivingLicenseWhileDrinkingAndDriving, ConfiscationOfDrivingLicenseAsAResultOfDriftOrSpinViolation, ContactInfo, FAQ, ContactForm, NotFound } from './Handlers/Imports.jsx';
import './Styles/Global.css';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

function Loader() {
  const { pathname } = useLocation();

  useEffect(() => {
    NProgress.start();
    window.scrollTo(0, 0);
    NProgress.done();
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Router>
      <Loader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/hakkimizda" element={<About />} />
        <Route path="/vizyonumuz" element={<OurVision />} />
        <Route path="/misyonumuz" element={<OurMission />} />
        <Route path="/psikoteknik-nedir" element={<WhatIsPsychotechnics />} />
        <Route path="/psikoteknik-raporunu-kimler-alir" element={<WhoGetsThePsychotechnicalReport />} />
        <Route path="/psikoteknik-belgesi-nasil-alinir" element={<HowToGetPsychotechnicalCertificate />} />
        <Route path="/psikoteknik-raporunun-gecerlilik-suresi" element={<ValidityPeriodOfPsychotechnicalReport />} />
        <Route path="/ehliyet-iade-icin-psikoteknik" element={<PsychotechnicForDrivingLicenseReturn />} />
        <Route path="/alkollu-arac-kullanirken-ehliyete-el-konulmasi" element={<ConfiscationOfDrivingLicenseWhileDrinkingAndDriving />} />
        <Route path="/drift-veya-spin-ihlali-sonucunda-ehliyete-el-konulmasi" element={<ConfiscationOfDrivingLicenseAsAResultOfDriftOrSpinViolation />} />
        <Route path="/iletisim-bilgilerimiz" element={<ContactInfo />} />
        <Route path="/sss" element={<FAQ />} />
        <Route path="/iletisim-formu" element={<ContactForm />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </StrictMode>
);