import React from 'react';
import { motion } from 'framer-motion';
import SimilatorPNG from '../Images/Similatör.png';

const SimulatorSection = () => {
    return (
        <div className="bg-gray-100 my-16">
            <div className="container mx-auto flex md:gap-4 flex-col md:flex-row items-center">
                <div className="md:w-1/2 hidden md:block">
                    <motion.img
                        src={SimilatorPNG}
                        alt="Simülatör"
                        className="rounded-lg shadow-lg h-full w-auto"
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: true, amount: 0.8 }}
                    />
                </div>
                <div className="md:w-1/2 mt-8 md:mt-0 text-center md:text-left">
                    <motion.h2
                        className="text-4xl font-bold text-gray-800"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: true, amount: 0.8 }}
                    >
                        Psikoteknik Belgenizi Bir Saat, 20 Dakikada Alın!
                    </motion.h2>
                    <motion.p
                        className="text-gray-600 mt-4"
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                        viewport={{ once: true, amount: 0.8 }}
                    >
                        Sektörde adından sıkça söz ettiren firmamız vizyonumuz, rakiplerimizden her zaman bir adım önde olmayı ve müşterilerimize en iyi kaliteyi sunmayı kendimize görev olarak bilmekteyiz.
                    </motion.p>
                </div>
            </div>
        </div>
    );
};

export default SimulatorSection;