import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const PsikoteknikNedir = () => {
    return (
        <>
            <Helmet>
                <title>Psikoteknik Nedir?</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Psikoteknik Nedir?</h1>
                    <p>
                        Psikoteknik, sürücülerin dikkat, algı, refleks, el-göz koordinasyonu gibi yeteneklerinin
                        değerlendirilmesi amacıyla yapılan bir dizi testtir. Bu testler, sürücülerin trafikteki
                        davranışlarını analiz ederek, sürüş güvenliğini artırmayı amaçlar.
                    </p>
                    <p className="mt-4">
                        Özellikle ticari araç sürücülerinin bu testleri geçmesi gerekmektedir. Psikoteknik
                        değerlendirmeleri, sürücülerin ruhsal ve fiziksel yeterliliklerini objektif bir şekilde
                        ölçerek, güvenli sürüş için gerekli donanımlara sahip olup olmadıklarını belirler.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default PsikoteknikNedir;