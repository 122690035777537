import React from 'react';
import { motion } from 'framer-motion';
import { FaLightbulb, FaCarCrash, FaRoad } from 'react-icons/fa';

const cardVariants = {
    offscreen: {
        y: 100,
        opacity: 0,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
};

const Services = () => {
    const services = [
        {
            title: 'Psikoteknik Belgesi',
            description: 'Sürücülerin psikolojik durumlarını değerlendiren bir testtir.',
            icon: FaLightbulb,
        },
        {
            title: 'Ehliyet Kaptırma (100 Ceza)',
            description: 'Belirli bir süre boyunca ehliyetin geçici olarak alınması durumudur.',
            icon: FaCarCrash,
        },
        {
            title: 'Yol Belgesi ve Şoför Kartı',
            description: 'Araç kullanma izni ve sürücü kimlik kartı alma sürecidir.',
            icon: FaRoad,
        }
    ];

    return (
        <div id="services" className="bg-gray-50 my-20">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold text-gray-800">Hizmetlerimiz</h2>
                <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
                    {services.map((service, index) => (
                        <motion.div
                            key={index}
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={cardVariants}
                            className="bg-white p-6 rounded-lg shadow-lg"
                        >
                            <div className="flex justify-center">
                                <service.icon className="text-4xl text-gray-800 mb-4" />
                            </div>
                            <h3 className="text-2xl font-bold text-gray-800">{service.title}</h3>
                            <p className="text-gray-600 mt-4">{service.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;