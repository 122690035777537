import React from 'react';
import { motion } from 'framer-motion';
import { FaUser } from 'react-icons/fa';

const Testimonials = () => {
    const testimonials = [
        {
            name: 'Çağatay Ar***',
            feedback: 'Öncelikle ilginiz için çok teşekkürler, Psikoloğunuzun sayesinde hızlıca testi geçip belgemi teslim aldım.',
            icon: FaUser,
        },
        {
            name: 'Furkan Yo***',
            feedback: 'Şöför kartı almak için teste girdikten sonra yardımcı olduğunuz için teşekkürler.',
            icon: FaUser,
        }
    ];

    return (
        <div id="testimonials" className="bg-white my-20">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold text-gray-800">Referanslar</h2>
                <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
                    {testimonials.map((testimonial, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, x: -50 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                            viewport={{ once: true, amount: 0.8 }}
                            className="bg-gray-100 p-6 rounded-lg shadow-lg"
                        >
                            <div className="flex justify-center">
                                <testimonial.icon className="text-6xl text-gray-800 mb-4" />
                            </div>
                            <p className="text-gray-600">"{testimonial.feedback}"</p>
                            <p className="mt-4 text-gray-800 font-bold">{testimonial.name}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Testimonials;