import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const DriftVeyaSpinIhlaliSonucundaEhliyeteElKonulmasi = () => {
    return (
        <>
            <Helmet>
                <title>Drift veya Spin İhlali Sonucunda Ehliyetinize El Konulması</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Drift Veya Spin İhlali Sonucunda Ehliyete El Konulması</h1>
                    <p>
                        Drift veya spin ihlali yapan sürücüler, trafikte tehlike oluşturmaları nedeniyle
                        ehliyetlerine el konulması durumu ile karşı karşıya kalabilirler. Bu gibi durumlarda,
                        sürücüler ehliyetlerini geri alabilmek için psikoteknik testlerinden geçmek zorundadır.
                    </p>
                    <p className="mt-4">
                        Psikoteknik değerlendirme, sürücünün bu tür ihlaller sonrasında trafikte güvenli
                        bir şekilde araç kullanıp kullanamayacağını belirler.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default DriftVeyaSpinIhlaliSonucundaEhliyeteElKonulmasi;