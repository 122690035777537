import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const AlkolluAracKullanirkenEhliyeteElKonulmasi = () => {
    return (
        <>
            <Helmet>
                <title>Alkollü Araç Kullanırken Ehliyetinize El Konulması</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Alkollü Araç Kullanırken Ehliyete El Konulması</h1>
                    <p>
                        Alkollü araç kullanırken ehliyetine el konulan sürücülerin, ehliyetlerini geri
                        alabilmeleri için psikoteknik testlerine girmeleri gerekmektedir. Bu testler,
                        sürücünün alkollü araç kullanımı sonucu ortaya çıkan risk faktörlerini değerlendirmeyi
                        amaçlar.
                    </p>
                    <p className="mt-4">
                        Başarılı bir değerlendirme sonucunda, sürücüler ehliyetlerini geri alabilirler.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default AlkolluAracKullanirkenEhliyeteElKonulmasi;