import React from 'react';
import { motion, useCycle } from 'framer-motion';
import Image1 from '../Images/Görsel1.png';
import Image2 from '../Images/Görsel2.jpg';
import Image3 from '../Images/Görsel3.jpg';

const backgrounds = [
    `url(${Image1})`,
    `url(${Image2})`,
    `url(${Image3})`
];

const heroVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};

const Hero = () => {
    const [currentBackground, cycleBackground] = useCycle(...backgrounds);

    React.useEffect(() => {
        const interval = setInterval(() => {
            cycleBackground();
        }, 4500);
        return () => clearInterval(interval);
    }, [cycleBackground]);

    return (
        <div className="relative overflow-hidden bg-white text-gray-900">
            <div className="absolute inset-0">
                <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition={{ duration: 1.5 }}
                    variants={heroVariants}
                    key={currentBackground}
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: currentBackground,
                        height: 'auto',
                        width: '100%',
                    }}
                />
            </div>
            <div className="relative z-10 py-32">
                <div className="container mx-auto text-center">
                    <motion.h1
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.7 }}
                        className="text-5xl font-extrabold text-white"
                        style={{ textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)' }}
                    >
                        <span
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                zIndex: 1,
                                padding: '0 8px',
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                backdropFilter: 'blur(5px)',
                                borderRadius: '5px',
                            }}
                        >
                            Psikoteknik Testleri
                        </span>
                    </motion.h1>
                    <motion.p
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.7, delay: 0.3 }}
                        className="text-lg mt-6 text-white"
                        style={{ textShadow: '1px 1px 6px rgba(0, 0, 0, 0.7)' }}
                    >
                        <span
                            style={{
                                display: 'inline-block',
                                position: 'relative',
                                zIndex: 1,
                                padding: '0 8px',
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                backdropFilter: 'blur(5px)',
                                borderRadius: '5px',
                            }}
                        >
                            Performansınızı test edin ve geliştirin!
                        </span>
                    </motion.p>
                    <a href="/iletisim-formu">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}
                            className="mt-8 px-8 py-4 bg-blue-500 text-white font-semibold rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
                        >
                            Hemen Başla
                        </motion.button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Hero;