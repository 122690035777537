import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [status, setStatus] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('WAIT');

        try {
            const response = await axios.post('https://formspree.io/f/mzzpgvpw', formData, {
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                setStatus('SUCCESS');
            } else {
                setStatus('ERROR');
            }
        } catch (error) {
            setStatus('ERROR');
        }
    };

    return (
        <>
            <Helmet>
                <title>İletişim Formu</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="bg-gray-50 my-20 flex-grow">
                    <div className="container mx-auto text-center">
                        <h2 className="text-4xl font-bold text-gray-800">İletişim Formu</h2>
                        <motion.form
                            initial={{ opacity: 0, scale: 0.8 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                            className="mt-8 max-w-lg mx-auto"
                            onSubmit={handleSubmit}
                        >
                            <div className="mb-4">
                                <motion.input
                                    whileFocus={{ borderColor: '#3b82f6' }}
                                    type="text"
                                    name="name"
                                    placeholder="Adınız"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-4">
                                <motion.input
                                    whileFocus={{ borderColor: '#3b82f6' }}
                                    type="email"
                                    name="email"
                                    placeholder="Email Adresiniz"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-4">
                                <motion.textarea
                                    whileFocus={{ borderColor: '#3b82f6' }}
                                    name="message"
                                    placeholder="Mesajınız"
                                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                    value={formData.message}
                                    onChange={handleChange}
                                ></motion.textarea>
                            </div>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                type="submit"
                                className={`px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300 ${status === 'WAIT' || status === 'SUCCESS' ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={status === 'WAIT' || status === 'SUCCESS'}
                            >
                                {status === 'WAIT' ? 'Gönderiliyor...' : status === 'SUCCESS' ? 'Gönderildi' : 'Gönder'}
                            </motion.button>
                            {status === 'SUCCESS' && (
                                <div className="mt-4 text-green-500">Mesajınız başarıyla gönderildi!</div>
                            )}
                            {status === 'ERROR' && (
                                <div className="mt-4 text-red-500">Mesaj gönderilirken bir hata oluştu.</div>
                            )}
                        </motion.form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default ContactForm;