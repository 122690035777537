import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const Vizyonumuz = () => {
    return (
        <>
            <Helmet>
                <title>Vizyonumuz</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Vizyonumuz</h1>
                    <p>
                        Vizyonumuz, Türkiye'nin en güvenilir psikoteknik değerlendirme merkezi olarak,
                        yol güvenliğini artırmak ve sürücülerin psikolojik yeterliliklerini en iyi şekilde
                        analiz ederek trafikte güvenli bir sürüş sağlamaktır.
                    </p>
                    <p className="mt-4">
                        İnovasyon, kalite ve güvenilirlik prensipleri doğrultusunda, her bireyin trafikte
                        güvenliğini ön planda tutan bir hizmet anlayışı geliştirmek hedefimizdir.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Vizyonumuz;