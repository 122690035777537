import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const faqItems = [
    {
        trigger: "Psikoteknik nedir?",
        content: "Psikoteknik değerlendirme, sürücülerin psikolojik ve fiziksel yeteneklerini ölçen bir testtir.",
    },
    {
        trigger: "Kimler psikoteknik raporu almalıdır?",
        content: "Ticari araç sürücüleri ve ehliyetine el konulmuş sürücüler psikoteknik raporu almalıdır.",
    },
    {
        trigger: "Raporun geçerlilik süresi nedir?",
        content: "Psikoteknik raporları 5 yıl süreyle geçerlidir.",
    },
];

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <>
            <Helmet>
                <title>Sıkça Sorulan Sorular</title>
            </Helmet>
            <div className="flex flex-col min-h-screen bg-white">
                <Navbar />
                <div className="my-20 flex-grow">
                    <div className="container mx-auto">
                        <h2 className="text-4xl font-bold text-gray-800 text-center">Sıkça Sorulan Sorular</h2>
                        <div className="mt-8 max-w-3xl mx-auto">
                            {faqItems.map((item, index) => (
                                <div key={index} className="mb-4 border rounded-md overflow-hidden">
                                    <button
                                        onClick={() => toggleAccordion(index)}
                                        className="w-full px-4 py-2 text-left bg-gray-100 border-b focus:outline-none"
                                    >
                                        <h3 className="text-xl font-semibold text-gray-700">{item.trigger}</h3>
                                    </button>
                                    <AnimatePresence>
                                        {openIndex === index && (
                                            <motion.div
                                                initial={{ opacity: 0, maxHeight: 0 }}
                                                animate={{ opacity: 1, maxHeight: 500 }} // MaxHeight'i büyük bir değere ayarlayın
                                                exit={{ opacity: 0, maxHeight: 0 }}
                                                transition={{ duration: 0.2, ease: 'easeOut' }} // Hızlı animasyon için süreyi kısaltın
                                                className="px-4 py-2"
                                            >
                                                <p className="text-gray-600">{item.content}</p>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default FAQ;