import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const PsikoteknikRaporunuKimlerAlir = () => {
    return (
        <>
            <Helmet>
                <title>Psikoteknik Raporunu Kimler Alır?</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Psikoteknik Raporunu Kimler Alır?</h1>
                    <p>
                        Psikoteknik raporu, genellikle ticari araç sürücüleri, şoförler ve yolcu taşıyan
                        araç sürücüleri tarafından alınır. Bu rapor, sürücülerin trafikte güvenli sürüş
                        yapabilmeleri için gerekli olan psikolojik ve fiziksel yeterliliklerini belgelemektedir.
                    </p>
                    <p className="mt-4">
                        Ayrıca, ehliyetine el konulan sürücülerin ehliyetlerini geri alabilmeleri için de
                        psikoteknik raporu gerekmektedir.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default PsikoteknikRaporunuKimlerAlir;