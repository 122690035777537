import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const PsikoteknikBelgesiNasilAlinir = () => {
    return (
        <>
            <Helmet>
                <title>Psikoteknik Belgesi Nasıl Alınır?</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Psikoteknik Belgesi Nasıl Alınır?</h1>
                    <p>
                        Psikoteknik belgesi, belirli testleri içeren bir değerlendirme sürecinin ardından
                        alınır. Bu süreçte, sürücülerin refleksleri, dikkat süreleri, el-göz koordinasyonu
                        gibi çeşitli yetenekleri test edilir. Test sonuçlarına göre sürücüye uygun bir
                        psikoteknik raporu verilir.
                    </p>
                    <p className="mt-4">
                        Bu testler, yetkili psikoteknik değerlendirme merkezlerinde yapılmakta olup, başarılı
                        sonuçlar elde eden sürücüler, bu belgeyi almaya hak kazanır.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default PsikoteknikBelgesiNasilAlinir;