import React from 'react';
import { motion } from 'framer-motion';
import { FaUsers, FaDollarSign, FaHeadset } from 'react-icons/fa';

const featureVariants = {
    offscreen: {
        opacity: 0,
        y: 100,
    },
    onscreen: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
        },
    },
};

const Features = () => {
    return (
        <div className="bg-gray-50 my-20">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-bold text-gray-800">Neden Bizi Tercih Etmelisiniz?</h2>
                <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
                    {[
                        { title: 'Profesyonel Kadromuz', description: 'En iyi ekip, en iyi sonuçlar.', icon: FaUsers },
                        { title: 'Uygun Fiyat Garantisi', description: 'En kaliteli hizmet, en uygun fiyatlarla.', icon: FaDollarSign },
                        { title: 'Her Zaman Yanınızdayız', description: '7/24 destek ile her zaman yanınızdayız.', icon: FaHeadset },
                    ].map((feature, index) => (
                        <motion.div
                            key={index}
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}
                            variants={featureVariants}
                            className="bg-white p-6 rounded-lg shadow-lg"
                        >
                            <div className="flex justify-center mb-4">
                                <feature.icon className="text-blue-500 text-4xl" />
                            </div>
                            <h3 className="text-2xl font-bold text-gray-800">{feature.title}</h3>
                            <p className="text-gray-600 mt-4">{feature.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Features;