import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const PsikoteknikRaporununGeçerlilikSüresi = () => {
    return (
        <>
            <Helmet>
                <title>Psikoteknik Raporunun Geçerlilik Süresi</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Psikoteknik Raporunun Geçerlilik Süresi</h1>
                    <p>
                        Psikoteknik raporu, genellikle 5 yıl süreyle geçerlidir. Bu süre zarfında sürücüler,
                        raporun gerektirdiği yeterliliklere sahip olduklarını belgeleyebilirler. Sürenin
                        dolmasının ardından, sürücüler yeniden psikoteknik testlere tabi tutulur ve yeni
                        bir rapor almaları gerekmektedir.
                    </p>
                    <p className="mt-4">
                        Raporun geçerlilik süresi dolduğunda, trafikte geçerli bir rapora sahip olmayan
                        sürücüler cezai yaptırımlarla karşı karşıya kalabilirler.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default PsikoteknikRaporununGeçerlilikSüresi;