import React from 'react';
import Navbar from '../Components/Navbar';
import Hero from '../Components/Hero';
import Services from '../Components/Services';
import Testimonials from '../Components/Testimonials';
import Contact from '../Components/Contact';
import Footer from '../Components/Footer';
import Features from '../Components/Features';
import SimilatorSection from '../Components/SimilatorSection';
import Helmet from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Anasayfa - Vatan Psikoteknik Değerlendirme Merkezi</title>
      </Helmet>
      <div>
        <Navbar />
        <Hero />
        <SimilatorSection />
        <Features />
        <Services />
        <Testimonials />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Home;