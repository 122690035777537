import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const EhliyetIadeIcinPsikoteknik = () => {
    return (
        <>
            <Helmet>
                <title>Ehliyet İade İçin Psikoteknik Testi</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Ehliyet İade İçin Psikoteknik Testi</h1>
                    <p>
                        Ehliyetine çeşitli sebeplerle el konulan sürücülerin, ehliyetlerini geri alabilmeleri
                        için psikoteknik testlerinden geçmeleri gerekmektedir. Bu testler, sürücünün trafikte
                        güvenli bir şekilde araç kullanıp kullanamayacağını değerlendirmeye yöneliktir.
                    </p>
                    <p className="mt-4">
                        Psikoteknik değerlendirme sonucunda başarılı olan sürücüler, ehliyetlerini geri
                        alabilirler.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default EhliyetIadeIcinPsikoteknik;