import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const Misyonumuz = () => {
    return (
        <>
            <Helmet>
                <title>Misyonumuz</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                < Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">Misyonumuz</h1>
                    <p>
                        Misyonumuz, sürücülerin trafik güvenliği konusunda bilinçlenmesini sağlamak ve
                        psikoteknik değerlendirmelerle sürüş güvenliğini artırmaktır. Her bir bireyin
                        trafikteki davranışlarını ve reflekslerini değerlendirerek, topluma güvenli sürücüler
                        kazandırmayı amaçlıyoruz.
                    </p>
                    <p className="mt-4">
                        Her bir sürücünün trafikte en iyi performansı göstermesi için gerekli psikolojik
                        ve fiziksel yeterlilikleri tespit etmeye yönelik çalışmalarımızla, trafik kazalarını
                        en aza indirmek için çaba sarf ediyoruz.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Misyonumuz;