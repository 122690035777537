import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const Hakkimizda = () => {
    return (
        <>
            <Helmet>
                <title>Hakkımızda</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="container mx-auto p-4 flex-grow">
                    <h1 className="text-3xl font-bold mb-4">
                        Hakkımızda
                    </h1>
                    <p>
                        Vatan Psikoteknik Değerlendirme Merkezi olarak, sürücülerin güvenliğini ve yol güvenliğini artırmak amacıyla
                        psikoteknik değerlendirme hizmetleri sunuyoruz. Amacımız, sürücülerin trafikteki
                        davranışlarını daha iyi anlamalarına yardımcı olmak ve topluma daha güvenli sürücüler
                        kazandırmaktır.
                    </p>
                    <p className="mt-4">
                        Uzman ekibimiz ve son teknoloji ekipmanlarımızla, psikoteknik raporları konusunda
                        güvenilir ve kaliteli hizmet sunmayı hedefliyoruz.
                    </p>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Hakkimizda;