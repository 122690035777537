import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import Helmet from 'react-helmet';

const ContactInfo = () => {
    return (
        <>
            <Helmet>
                <title>İletişim Bilgilerimiz</title>
            </Helmet>
            <div className="flex flex-col min-h-screen">
                <Navbar />
                <div className="bg-gray-50 my-20 flex-grow">
                    <div className="container mx-auto text-center">
                        <h2 className="text-4xl font-bold text-gray-800">İletişim Bilgilerimiz</h2>
                        <p className="mt-4 text-gray-600">
                            Adres: Şehremini, Ziya Gökalp Sokağı, 34098 Fatih/İstanbul
                        </p>
                        <p className="mt-2 text-gray-600">
                            1. Cep Hattımız: 0 (533) 087 73 14<br />
                            2. Cep Hattımız: 0 (542) 139 30 83<br />
                            Şirket Hattımız: 0 (212) 585 99 99
                        </p>
                        <p className="mt-2 text-gray-600">
                            Gmail: vatangrup2011@gmail.com
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default ContactInfo;